import React from "react";
import Layout from "../components/Layout/Layout";
import Seo from "../components/Core/Seo";
import { graphql } from "gatsby";
import Hero from "../components/CaseStudy/Hero";
import InfoBlock from "../components/CaseStudy/InfoBlock";
import PortfolioBlock from "../components/CaseStudy/PortfolioBlock";
import Image from "../components/Core/Image";
import ContentContainer from "../components/Core/Containers/ContentContainer";
import BlockContainer from "../components/Core/Containers/BlockContainer";
import Pagenation from "../components/CaseStudy/Pagenation";

const caseStudyTemplate = (props) => {
  const page = props.data.page;
  const previous = props.data.previous;
  const next = props.data.next;
  const { metaTitle, metaDescription, socialMediaImage } = page.seo;

  const path = props.location.pathname;

  let sharingImage = false;

  if (socialMediaImage) {
    sharingImage = socialMediaImage.file.url;
  }

  return (
    <Layout caseStudy>
      <Seo
        title={metaTitle}
        description={metaDescription}
        fullTitle={false}
        path={path}
        image={sharingImage}
      />
      <Hero
        image={page.hero.image}
        imageMobile={page.hero.imageMobile}
        text={page.hero.text}
      />
      <InfoBlock
        heading={page.heading}
        description={page.description}
        category={page.category.category}
        materials={page.materials}
        year={page.year}
        linkText={page.linkText}
        linkUrl={page.linkUrl}
      />
      {page.blocks.map((block, index) => {
        const { __typename: type } = block;
        const isLast = index === page.blocks.length - 1;
        return (
          <BlockContainer
            key={index}
            smMargin={!isLast ? 1 : 0}
            block={isLast ? 1 : 0}
          >
            <ContentContainer>
              {type === "ContentfulPortfolioBlock" ? (
                <PortfolioBlock entries={block.entries} />
              ) : type === "ContentfulImage" ? (
                <Image image={block.image} imageMobile={block.imageMobile} />
              ) : null}
            </ContentContainer>
          </BlockContainer>
        );
      })}
      {previous && next && <Pagenation previous={previous} next={next} />}
    </Layout>
  );
};

export default caseStudyTemplate;

export const pageQuery = graphql`
  query CaseStudyQuery($slug: String!, $previous: String!, $next: String!) {
    page: contentfulCaseStudy(slug: { eq: $slug }) {
      blocks {
        __typename
        ... on ContentfulPortfolioBlock {
          id
          entries {
            ...portfolioCardFragment
          }
        }
        ... on ContentfulImage {
          id
          image {
            description
            gatsbyImageData(width: 1920)
          }
          imageMobile {
            description
            gatsbyImageData(width: 1400)
          }
        }
      }
      category {
        category
      }
      description {
        raw
      }
      heading
      hero {
        ...heroFragment
      }
      linkText
      linkUrl
      materials
      seo {
        ...seoFragment
      }
      slug
      year
    }
    previous: contentfulCaseStudy(slug: { eq: $previous }) {
      slug
      heading
    }
    next: contentfulCaseStudy(slug: { eq: $next }) {
      slug
      heading
    }
  }
`;
