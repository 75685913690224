import React, { Fragment } from "react";
import styled from "styled-components";
import colors from "../../../../styles/colors";
import Image from "../../../Core/Image";
import { H4Style, H6Style } from "../../../Core/Styles/typography";
import { Link } from "gatsby";

const Container = styled.div`
  cursor: ${(props) => (props.hasPopup ? "pointer" : "auto")};

  a {
    text-decoration: none;
    color: inherit;
  }
`;

const ImageContainer = styled.div`
  text-align: center;
`;

const Details = styled.div`
  margin-top: 24px;
`;

const Title = styled.h3`
  ${H4Style};
`;

const Category = styled.p`
  ${H6Style};
  color: ${colors.greyMedium};
  margin-bottom: 4px;
`;

const PortfolioCard = ({
  landingPageImage,
  landingPageTitle,
  category,
  caseStudy,
  className,
  ...rest
}) => {
  const renderCardContent = () => {
    return (
      <>
        <ImageContainer>
          <Image image={landingPageImage} />
        </ImageContainer>
        <Details>
          <Category>{category.category}</Category>
          <Title>{landingPageTitle}</Title>
        </Details>
      </>
    );
  };

  return (
    <Container className={className}>
      {caseStudy ? (
        <Link to={`/work/${caseStudy.slug}`}>{renderCardContent()}</Link>
      ) : (
        renderCardContent()
      )}
    </Container>
  );
};

export default PortfolioCard;
