import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import ContentContainer from "../Core/Containers/ContentContainer";
import styled from "styled-components";
import breakpoints from "../../styles/breakpoints";

const HeroCotainer = styled.div`
  margin-bottom: 40px;
`;

const VideoContainer = styled.div`
  display: ${(props) => (props.mobile ? "block" : "none")};
  border-radius: 10px;
  overflow: hidden;
  video {
    display: block;
    width: 100%;
    height: 100%;
  }

  @media (min-width: ${breakpoints.md}) {
    border-radius: 20px;
    padding-bottom: 56.25%;
    position: relative;
    display: ${(props) => (!props.mobile ? "block" : "none")};
    video {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: unset;
    }
  }
`;


const HeroImage = styled(GatsbyImage)`
  border-radius: 20px;
`;

const DesktopImage = styled(HeroImage)`
  display: none !important;

  @media (min-width: ${breakpoints.md}) {
    display: block !important;
  }

`;

const MobileImage = styled(HeroImage)`
  display: block !important;

  @media (min-width: ${breakpoints.md}) {
    display: none !important;
  }
`;

const Hero = ({ image, imageMobile }) => {

  let mobile = image;

  if (imageMobile) {
    mobile = imageMobile;
  }

  return (
    <HeroCotainer>
      <ContentContainer>
        {image && image.file.contentType.includes("video") ? (
          <VideoContainer>
            <video src={image.file.url} muted loop playsInline autoPlay />
          </VideoContainer>
        ) : (
          <DesktopImage
            image={image.gatsbyImageData}
            alt={image.description || ""}
          />
        )}
        {mobile && mobile.file.contentType.includes("video") ? (
          <VideoContainer mobile>
            <video src={mobile.file.url} muted loop playsInline autoPlay />
          </VideoContainer>

        ) : (
          <MobileImage
            image={mobile.gatsbyImageData}
            alt={mobile.description || ""}
          />
        )}
      </ContentContainer>
    </HeroCotainer>
  );
};

export default Hero;
