import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import breakpoints from "../../styles/breakpoints";
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";
import ContentContainer from "../Core/Containers/ContentContainer";

const PagenationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 60px;
`;

const LinkContainer = styled.div`
  text-align: ${(props) => (props.next ? "right" : "left")};
  min-width: 50%;
  a {
    ${fonts.workSans};
    color: ${colors.greyDark};
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    margin-top: 20px;
    display: inline-block;
  }

  @media (min-width: ${breakpoints.md}) {
    a {
      font-size: 20px;
    }
  }
`;

const Pagenation = ({ previous, next }) => {
  return (
    <ContentContainer>
      <PagenationContainer>
        <LinkContainer>
          <div>Previous</div>
          <Link to={`/work/${previous.slug}`}>{previous.heading}</Link>
        </LinkContainer>
        <LinkContainer next>
          <div>Next</div>
          <Link to={`/work/${next.slug}`}>{next.heading}</Link>
        </LinkContainer>
      </PagenationContainer>
    </ContentContainer>
  );
};

export default Pagenation;
