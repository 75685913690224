import React from "react";
import styled from "styled-components";
import PortfolioCard from "../Index/PortfolioBlock/components/PortfolioCard";
import breakpoints from "../../styles/breakpoints";

const Grid = styled.div`
  display: grid;
  gap: 24px;

  @media (min-width: ${breakpoints.sm}) {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 54px;
  }
`;

const PortfolioBlock = ({ entries }) => {
  return (
    <Grid>
      {entries.map((entry, index) => {
        return <PortfolioCard {...entry} key={index} />;
      })}
    </Grid>
  );
};

export default PortfolioBlock;
