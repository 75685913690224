import React from "react";
import styled from "styled-components";
import BlockContainer from "../Core/Containers/BlockContainer";
import ContentContainer from "../Core/Containers/ContentContainer";
import ExternalLink from "../Core/ExternalLink";
import breakpoints from "../../styles/breakpoints";
import colors from "../../styles/colors";
import { H2Style, H5Style } from "../Core/Styles/typography";
import RichText from "../Core/RichText";
import fonts from "../../styles/fonts";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 40px;

  @media (min-width: ${breakpoints.md}) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 60px;
  }
  @media (min-width: ${breakpoints.lg}) {
    column-gap: 120px;
  }
`;

const LeftColumn = styled.div``;

const RightColumn = styled.div`
  display: grid;
  row-gap: 16px;

  @media (min-width: ${breakpoints.sm}) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 24px;
    row-gap: 24px;
  }
`;

const Heading = styled.h1`
  ${H2Style};
  color: ${colors.greyDarker};
`;

const Description = styled.div`
  color: ${colors.greyDark};
  margin-top: 12px;
`;

const InfoBox = styled.div`
  ${H5Style};
  color: ${colors.greyDarker};
  a {
    color: inherit;
    text-decoration: none;
  }

  :first-of-type {
    order: 1;
  }
  :nth-of-type(2) {
    order: 3;
  }
  :nth-of-type(3) {
    order: 2;
  }
  :nth-of-type(4) {
    order: 4;
  }
`;

const InfoHeading = styled.h3`
  ${fonts.openSans};
  color: ${colors.greyMedium};
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 4px;
`;

const InfoText = styled.p``;

const InfoBlock = ({
  heading,
  description,
  category,
  year,
  materials,
  linkText,
  linkUrl,
}) => {
  return (
    <BlockContainer block>
      <ContentContainer>
        <Grid>
          <LeftColumn>
            <Heading>{heading}</Heading>
            <Description>
              <RichText content={description} />
            </Description>
          </LeftColumn>
          <RightColumn>
            {category && (
              <InfoBox>
                <InfoHeading>Category</InfoHeading>
                <InfoText>{category}</InfoText>
              </InfoBox>
            )}
            {year && (
              <InfoBox>
                <InfoHeading>Year</InfoHeading>
                <InfoText>{year}</InfoText>
              </InfoBox>
            )}
            {materials && (
              <InfoBox>
                <InfoHeading>Materials</InfoHeading>
                <InfoText>
                  {materials.map((material, index) => {
                    const isLast = index === materials.length - 1;
                    return `${material}${isLast ? "" : ", "}`;
                  })}
                </InfoText>
              </InfoBox>
            )}
            {linkUrl && linkText && (
              <InfoBox>
                <InfoHeading>Link</InfoHeading>
                <ExternalLink
                  href={linkUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {linkText}
                </ExternalLink>
              </InfoBox>
            )}
          </RightColumn>
        </Grid>
      </ContentContainer>
    </BlockContainer>
  );
};

export default InfoBlock;
